<script>
import SubHeader from "@/components/SubHeader";
import Calendar from "@/components/Calendars";
export default {
  components: {
    Calendar,
    SubHeader,
  },
  data: () => ({
    headerProps: {
      title: "Gerenciar Viagens",
      btns: [
        {
          text: "Novo",
          color: "primary",
          action: () => {},
          icon: "mdi-plus",
          path: "/cadastros-operacionais/viagens/new",
          isRedirector: true,
        },
        {
          text: "Viagem Extra",
          color: "primary",
          action: () => {},
          icon: "mdi-av-timer",
          path: "",
          isRedirector: false,
        },
        {
          text: "Recarregar",
          color: "primary",
          action: () => {},
          icon: "mdi-update",
          path: "",
          isRedirector: false,
        },
      ],
    },
    isModalVisible: false,
    search: "",
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 2000,
    },
    items: [],
    headers: [
      { text: "Código", value: "codViag", sortable: false },
      { text: "Prefixo", value: "prefixo", sortable: false },
      { text: "Descrição", value: "descricao", sortable: false },
      { text: "Horário", value: "horario", sortable: false },
      { text: "Data", value: "data_viagem", sortable: false },
      { text: "Sentido", value: "sentido", sortable: false },
      { text: "Extra", value: "extra", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    // MODAL
    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
    dados: {
      interval: 0,
      data_final: null,
      data_viagem: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      codigo: null,
      extra: 1,
    },
    // MODAL
  }),
  created() {
    this.initialize();
    this.headerProps.btns[1].action = () => this.showModal();
    this.headerProps.btns[2].action = () => this.initialize();
  },

  methods: {
    handleSearch: function (value) {
      this.search = value;
    },
    // MODAL
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/viagens/extra`, this.dados) // link da API
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          if (r.data.success) {
            this.message.text = r.data.success;
            this.message.snacshow = true;
            this.message.type = "success";
            this.closeModal();
            this.initialize();
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    // MODAL
    initialize() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http.get("/viagens/full").then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    editar(id) {
      this.$router.push(`/cadastros-operacionais/viagens/editar/${id}`);
    },
    deleteItem(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .post(`/viagens/delete/${id}`)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.snacshow = true;
              this.message.type = "success";
              this.message.text = r.data.success;
              this.initialize();
            } else {
              this.message.snacshow = true;
              this.message.type = "error";
              this.message.text = r.data.error;
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "error";
          });
      }
    },
  },
};
</script>
